@import 'variables';
@import 'mixins';

.home-container {
    max-width: 600px;
    margin: auto;
}

.device-quick-add-form {
    a {
        margin: 0 20px 0 0;
    }

    .clipboard-copy-success {
    	display: inline-block;
    	padding-left: 7px;
    }
}


