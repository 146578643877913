@import 'variables';
@import 'mixins';

.navbar {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.navbar-nav {
    flex-direction: row;
}

.navbar img {
    max-width: 300px;

    @media(max-width: 768px) {
        width: 200px;
    }

    @media(max-width: 400px) {
        width: 100%;
    }
}

.navbar-brand {
    @media(max-width: 400px) {
        text-align: center;
        margin-bottom: 10px;
    }
}

.navbar .no-chevron:after {
    display: none;
}

.navbar-light .navbar-nav .nav-link {
    color: #FFF;
    padding-right: .5rem;
    padding-left: .5rem;

    &:hover,
    &:focus {
        color: @orange;
    }

    @media(max-width: 500px) {
        font-size: 14px;
    }

    @media(max-width: 400px) {
        width: 100%;
    }
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
    @media (max-width: 1600px) {
        left: -100px;
        position: absolute;
    }

    @media (max-width: 600px) {
        left: -130px;
    }

    @media (max-width: 500px) {
        left: -150px;
        font-size: 14px;
    }

    @media(max-width: 400px) {
        left: 0;
    }
}
