@import 'variables';
@import 'mixins';

/* Global elements Styles */
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

html { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; font-size-adjust: 100%; }
body, button, input, select, textarea { color: @main-color; font-family: @primary-site-font; font-weight: @weight-normal; .font-size(16); line-height: 1.3; }
body { background-color:#EEE; margin: 0; max-width: @max-site-width; }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 { clear: both; }
h1, h2, h3, h4, h5, h6 { margin: 0 0 20px 0; }

/* enter customer header styles for each media break point below */
h1, .h1 { .font-size(34); line-height:1.25; font-weight: @weight-bold; }
h2, .h2 { .font-size(29); line-height:1.25; font-weight: @weight-bold; color: #777; }
h3, .h3 { .font-size(20); line-height:1.25; font-weight: @weight-bold; color: #777; }
h4, .h4 { .font-size(18); line-height:1.25; font-weight: @weight-bold; }
h5, .h5 { .font-size(29); line-height:1.25; font-weight: @weight-bold; color: #777; }
h6, .h6 { .font-size(16); line-height:1.25; }

.p-small {
    .font-size(15);
}

@media (max-width: @tablet) {
    h1, .h1 { .font-size(34); line-height:1.25; }
    h2, .h2 { .font-size(29); line-height:1.25; }
    h3, .h3 { .font-size(20); line-height:1.25; }
    h4, .h4 { .font-size(18); line-height:1.25; }
    h5, .h5 { .font-size(29); line-height:1.25; }
    h6, .h6 { .font-size(16); line-height:1.25; }
}
@media (max-width: @sm-tablet) {
    h1, .h1 { .font-size(34); line-height:1.25; }
    h2, .h2 { .font-size(29); line-height:1.25; }
    h3, .h3 { .font-size(20); line-height:1.25; }
    h4, .h4 { .font-size(18); line-height:1.25; }
    h5, .h5 { .font-size(29); line-height:1.25; }
    h6, .h6 { .font-size(16); line-height:1.25; }
}

a { text-decoration: underline; color: @link-color; }
a:hover, a:focus { color: @link-color-hover; }



.main-black { color: @main-black; }
.orange { color: @orange; }
.blue { color: @blue; }
.light-gray { color: @light-gray; }
.dark-gray { color: @dark-gray; }
.red { color: red; }

.bg-main-black { background-color: @main-black; }
.bg-orange { background-color: @orange; }
.bg-blue { background-color: @blue; }
.bg-light-gray { background-color: @light-gray; }
.bg-dark-gray { background-color: @dark-gray; }

.bold { font-weight: bold; }


hr {
    margin: 10px 0;
}

hr:not([size]) {
    height: 0px;
}

table {
    border-collapse: collapse;

    th, td {
        border: 1px solid gray;
        padding: 5px;
        height: 38px;
    }
}

.btn {
    padding: 0.7rem 1.5rem;
    font-size: 18px;
    color: #fff;
    background-color: @orange;
    border-color: @orange;
}

.btn:hover,
.btn:focus, {
    color: #fff;
    background-color: #414042;
    border-color: #414042;
}

.btn-link {
    display: inline;
    background-color: transparent;
    border: none;
    text-decoration: underline;
    padding: 0;
    outline: none;
}

.btn:disabled {
    padding: 0.7rem 1.5rem;
    font-size: 18px;
    color: #fff;
    background-color: @orange;
    border-color: @orange;
    opacity: 0.5;
}

.no-margin {
    margin: 0;
}

/* Site Widths
 * review styleguide.less to review current variables.
 */
.site-width { width: 100%; max-width: @site-width; margin: auto; padding-left: 0; padding-right: 0; }
.lg-site-width { width: 100%; max-width: @lg-site-width; margin: auto; padding-left: 0; padding-right: 0; }
.med-site-width { width: 100%; max-width: @med-site-width; margin: auto; padding-left: 0; padding-right: 0; }
.sm-site-width { width: 100%; max-width: @sm-site-width; margin: 0 auto; padding-left: 0; padding-right: 0; }
.xsm-site-width { width: 100%; max-width: @xsm-site-width; margin: 0 auto; padding-left: 0; padding-right: 0; }

 @media(max-width: (@site-width + 40)) {
        .site-width { padding-left: 20px; padding-right: 20px; }
        .site-width.no-padding { padding-left: 0; padding-right: 0; }
}

 @media(max-width: (@lg-site-width + 40)) {
        .lg-site-width { padding-left: 20px; padding-right: 20px; }
        .lg-site-width.no-padding { padding-left: 0; padding-right: 0; }
}

 @media(max-width: (@med-site-width + 40)) {
        .med-site-width { padding-left: 20px; padding-right: 20px; }
        .med-site-width.no-padding { padding-left: 0; padding-right: 0; }
}
 
 @media(max-width: (@sm-site-width + 40)) {
        .sm-site-width { padding-left: 20px; padding-right: 20px; }
        .sm-site-width.no-padding { padding-left: 0; padding-right: 0; }
}
 
 @media(max-width: (@xsm-site-width + 40)) {
        .xsm-site-width { padding-left: 20px; padding-right: 20px; }
        .xsm-site-width.no-padding { padding-left: 0; padding-right: 0; }
}

.pointer:hover { cursor: pointer; }
.margin-left-0 { margin-left: 0; }
