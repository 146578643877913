@import 'variables';
@import 'mixins';

.page-footer {
    padding: 15px;
}

footer {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    color: #FFF;
}

footer .footer-inner {
    .flex-display();
    .justify-content(space-between);
    .align-items(center);
}

footer .footer-logo {
    display: block;
}

footer a,
footer .btn-link {
    color: #FFF;
    text-decoration: none;

    &:focus,
    &:hover {
        color: @orange;
    }
}

@media(max-width: 500px) {
    footer .footer-inner {
        .flex-wrap(wrap);
    }

    footer .footer-inner > div {
        width: 100%;
    }

    footer .footer-inner > div:not(:first-of-type) {
        padding-top: 30px;
    }
}


