@import 'variables';
@import 'mixins';

.verify-email {
    button {
        &:extend(.btn);
    }
}

.page-main {
    padding-top: 20px;
    padding-bottom: 40px;
}

@media(max-width: 600px) {
    .col {
        flex: 1 0 auto;
    }
}

.modal-header {
    background-color: @orange;
    padding: .7rem 1rem;

    h5 {
        color: @white;
    }
}

.modal-dialog .group {
    .box-shadow(none);
}

h2 i {
    opacity: .3;
}

.required-label {
    color: red;
    margin-left: 2px;
}

/*******Data Tables*********/
.dataTables_wrapper {
    padding-bottom: 10px;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter {
    padding-bottom: 15px;
}

.dataTables_wrapper .dataTables_length {
    @media(max-width: 640px) {
        text-align: left;
    }
}

.dataTables_wrapper .dataTables_filter {
    display: none;
}

.dataTables_wrapper .dataTables_info {
    padding-top: 1.255em;
}

.dataTables_wrapper .dataTables_paginate {
    padding-top: .75em;
}

.dataTables_wrapper .dataTables_length select {
    min-width: 60px;
    margin: 0 10px;
}

.dataTables_length > label {
    margin-left: 0;
}

.dataTables_wrapper input {
    width: 100%;
    font-size: 12px;
}

.data-table select {
    width: 100%;
    font-size: 12px;
}

.dataTables_wrapper .dataTables_filter input {
    margin-left: 10px;
}

table.dataTable tbody tr.even {
    background-color: #EEE;
}

.shadow-sm {
    box-shadow: none !important;
}

table.dataTable tbody td.box-usage {
    padding: 0;
}

table.dataTable tbody td.box-usage .time,
table.dataTable tbody td.box-usage .percentage div {
    padding: 18px 10px;
    display: block;
}

table.dataTable tbody td.box-usage .col-4 {
    padding: 0 !important;
}

table.dataTable thead th,
table.dataTable thead td,
table.dataTable tbody th,
table.dataTable tbody td {
    @media(max-width: 768px) {
        padding: 5px 15px 5px 5px;
        font-size: 15px;
    }

    @media(max-width: 500px) {
        padding: 2px 15px 2px 2px;
        font-size: 13px;
    }
}

.section {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: white;
    padding: 60px 30px;

    @media(max-width: 1024px) {
        padding-top: 40px;
        padding-bottom: 40px;
        padding: 40px 20px;
    }
}

.form-control {
    padding: .8rem .75rem;
}

.form-select {
    padding: .8rem 2.25rem .8rem .75rem;

    option {
        padding: 10px;
    }
}

.orange-header {
    background-color: @orange;
    color: @white;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 30px;
    font-family: @orbitron;

    h1,
    h2 {
        color: @white;
        margin: 0;
    }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: #FFF !important;
    border: 1px solid @blue;
    background: @blue;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    color: white !important;
    border: 1px solid @blue;
    background: @blue;
}

@media(max-width: 1024px) {
    table.dataTable tbody td {
        font-size: 14px;
    }
}


.change-currencies-select {
    float: right;
    margin-bottom: -30px;
    max-width: 250px;
    position: relative;
    z-index: 2;

    @media(max-width: 640px) {
        float: none;
        margin-bottom: 40px;
    }
}

.print-serial-number,
.form-response .barcode {
    display: none;
}

@media print {
    .print-code {
        background-color: #FFF;
        -webkit-print-color-adjust: exact !important;

        .page-main,
        .container,
        .mb-3 {
            padding: 0 !important;
            margin: 0 !important;
        }

        h1, h2, h3, h4, h5, h6,
        .orange-header,
        header,
        footer,
        .section,
        .alert,
        .alert-success {
            display: none !important;
        }

        .navbar {
            background-color: #000;
        }

        .device-quick-add-form {
            display: block !important;
        }

        .form-response {
            display: block !important;
        }

        .section.form-response-section {
            display: block !important;
            padding: 0;

            h2, h4,
            .col-form-label,
            .input-group {
                display: none !important;
            }

            .form-response {
                display: block !important;

                h4 {
                    display: block !important;
                }

                h4 span {
                    display: none !important;
                }

                .barcode,
                .barcode *,
                .barcode div {
                    display: block !important;
                }
            }

            .print-serial-number {
                display: block !important;
            }

            .device-quick-add-form {
                .btn.btn-primary {
                    display: none !important;
                }
            }
        }
    }
}

.validation-errors {
    color: red;
    padding: 10px;
    margin-bottom: 20px;

    .mb-4 {
        margin-bottom: 0 !important;
    }

    div {
        font-size: 20px;
        font-weight: 700;
    }

    ul {
        margin: 0 !important;
        font-size: 16px;
    }
}

.green-cell {
    color: #0f5132;
    background-color: #d1e7dd;
    font-weight: bold;
}

.yellow-cell {
    color: #7d7d00;
    background-color: #ffff00;
    font-weight: bold;
}

.red-cell {
    color: #ff0000;
    background-color: #ffc1c1;
    font-weight: bold;
}

